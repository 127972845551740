<template>
  <div
    class="flex-col flex w-full sm:w-full md:w-full lg:w-auto xl:w-auto text-right"
  >
    <div
      v-if="isOver"
      class="container flex sm:flex-row md:flex-row lg:flex-col xl:flex-col justify-between lg:my-0 xl:my-0 mt-2"
    >
      <button
        type="button"
        disabled
        class="inline-block w-full items-center px-4 py-2 border border-transparent text-md leading-5 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 active:bg-indigo-200 opacity-50 cursor-not-allowed"
      >
        Meeting Ended
      </button>
    </div>
    <div
      v-else-if="hasMeeting"
    >
      <div
        class="container flex flex-row sm:flex-col md:flex-col lg:flex-col xl:flex-col justify-between lg:my-0 xl:my-0"
      >
        <a
          :href="url"
          :disabled="!active"
          target="_blank"
          class="inline-block sm:w-auto md:w-full text-center py-2 mb-2 border border-transparent leading-5 text-md px-2 font-medium md:px-4 md:text-sm md:font-medium lg:text-sm lg:font-medium lg:px-4 xl:text-sm xl:font-medium xl:px-4 rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 active:bg-indigo-200"
          :class="{ 'opacity-50 cursor-not-allowed': !active, 'w-8/12': !isFutureMeeting, 'w-full': isFutureMeeting }"
        >
          <a-icon
            v-if="active"
            name="microphone"
            class="text-indigo-700 mr-2"
          />
          {{ meetingButtonTitle }}
        </a>
        <div
          v-if="password && active"
          class="sm:w-auto md:w-full mt-2"
          :class="{'w-4/12 ': !isFutureMeeting, 'w-full': isFutureMeeting}"
        >
          <span
            class="px-4 py-2 rounded-md text-sm leading-5 bg-red-200 text-red-800"
          >
            <a-icon
              name="key"
              class="text-red-800"
            />
            {{ password }}
          </span>
        </div>
      </div>
      <div
        v-if="isFutureMeeting"
        class="px-1 py-1 rounded-md text-center text-xs leading-5 bg-red-200 text-red-800"
      >
        link will be available closer to the meeting start time
      </div>
    </div>
    <div
      v-else
      class="container flex sm:flex-row md:flex-row lg:flex-col xl:flex-col justify-between lg:my-0 xl:my-0"
    >
      <button
        type="button"
        class="inline-block w-full items-center px-4 py-2 mb-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 active:bg-indigo-200 opacity-50 cursor-not-allowed"
      >
        Missing Meeting ID
      </button>
    </div>
  </div>
</template>

<script>
import { isEmpty, get } from '@/utils/lodash';
export default {
  props: {
    row: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    isOver: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    meeting() {
      return this.row.meeting;
    },
    host() {
      return this.row.host;
    },
    attendee() {
      return this.row.attendee;
    },
    isHost() {
      return get(this.host, 'id') == get(this.attendee, 'id');
    },
    hasMeeting() {
      return !isEmpty(this.meeting.id);
    },
    url() {
      if (!this.active) {
        return null;
      }

      return this.isHost ? this.meeting.start : this.meeting.join;
    },
    password() {
      return this.meeting.password;
    },
    meetingButtonTitle() {
      if (!this.active) {
        return 'Future Meeting';
      }
      return this.isHost ? 'Start Meeting' : 'Join Meeting';
    },
    isFutureMeeting() {
      return !this.active && !this.isOver;
    }
  }
};
</script>