<template>
  <div class="max-w-7xl mx-auto py-6 lg:px-8">
    <a-heading> Management Dashboard </a-heading>
    <div class="mt-2 grid grid-cols-1 gap-2 md:grid-cols-1 lg:grid-cols-2">
      <upcoming-schedule />
    </div>
  </div>
</template>

<script>
import UpcomingSchedule from '@/components/management/dashboard/upcoming-schedule.vue';
export default {
  components: {
    UpcomingSchedule
  }
};
</script>