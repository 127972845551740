<template>
  <div
    v-for="i in times"
    :key="i"
    class="flex flex-col rounded-lg shadow-lg overflow-hidden mb-4"
  >
    <div class="flex-shrink-0">
      <div class="rounded-sm bg-white h-12 w-full p-4">
        <div class="h-4 bg-gray-400 rounded animate-pulse w-1/4" />
      </div>
    </div>
    <div
      v-for="row in rows"
      :key="row"
      class="flex-1 bg-white flex flex-col justify-between"
    >
      <div class="block">
        <div class="px-4 py-4 sm:px-6">
          <div class="flex items-center justify-between">
            <div class="flex-1 space-y-4 py-1">
              <div class="h-4 bg-gray-400 rounded w-1/4 animate-pulse" />
            </div>
            <div class="h-4 bg-gray-400 rounded animate-pulse" />
          </div>
          <div class="mt-2">
            <div class="flex justify-between">
              <div class="w-1/2 flex">
                <div
                  class="h-2 bg-gray-400 rounded w-1/4 animate-pulse mr-4"
                />
                <div class="h-2 bg-gray-400 rounded w-1/4 animate-pulse" />
              </div>
              <div class="h-2 bg-gray-400 rounded w-1/5 animate-pulse" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { times } from '@/utils/lodash';
export default {
  props: {
    cards: {
      type: [Number, String],
      default: 1
    },
    items: {
      type: [Number, String],
      default: 3
    }
  },
  computed: {
    times() {
      return times(this.cards);
    },
    rows() {
      return times(this.items);
    }
  }
};
</script>