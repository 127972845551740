<template>
  <time
    :datetime="date"
    v-text="formattedDate"
  />
</template>

<script>
import { get } from '@/utils/lodash';
import dayjs, { format, abbreviation } from 'utils/dates';
export default {
  props: {
    date: {
      type: String,
      required: true
    },
    format: {
      type: String,
      default: 'LT'
    },
    timezone: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    localTimezone() {
      return dayjs.tz.guess();
    },
    formattedTimezone() {
      return get(abbreviation, 'abbr');
    },
    formattedDate() {
      let date = format(this.date, this.format);

      if (this.timezone) {
        date += ' ' + this.formattedTimezone;
      }

      return date;
    }
  }
};
</script>