<script>
/* eslint-disable vue/no-v-html */
import dayjs from '@/utils/dates';
import MeetingDate from './date';
import MeetingButton from './button';
import { isEmpty, size, get, head, join, map } from '@/utils/lodash';
export default {
  components: {
    MeetingDate,
    MeetingButton
  },
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  computed: {
    isOverClass() {
      return this.isOver ? 'text-opacity-50' : '';
    },
    actualMeeting() {
      return this.meeting.meeting;
    },
    start() {
      return this.meeting.start;
    },
    end() {
      return this.meeting.end;
    },
    title() {
      if (size(this.attendees) > 1) {
        return `Group Call with ${this.host.company}`;
      }

      if (
        get(this.host, 'company') &&
        !isEmpty(this.firstAttendee) &&
        get(this.firstAttendee, 'company')
      ) {
        return `${this.host.company} <> ${this.firstAttendee.company}`;
      }

      return get(this.host, 'company');
    },
    isHost() {
      return get(this.host, 'id') == get(this.attendee, 'id');
    },
    host() {
      return get(this.meeting, 'host');
    },
    attendee() {
      return get(this.meeting, 'attendee');
    },
    hostName() {
      return get(this.host, 'fullname');
    },
    firstAttendee() {
      return head(this.attendees);
    },
    attendees() {
      return get(this.meeting, 'attendees');
    },
    shouldShowNames() {
      return this.hasMultipleAttendees || this.isHost;
    },
    hasMultipleAttendees() {
      return size(this.attendees) > 1;
    },
    names() {
      return join(
        map(this.attendees, attendee => this.getAttendeeName(attendee)),
        '<br />'
      );
    },
    isActive() {
      return !isEmpty(this.actualMeeting.join);
    },
    isOver() {
      return dayjs()
        .subtract(5, 'minutes')
        .isAfter(dayjs(this.meeting.end));
    },
    isInProgress() {
      return dayjs().isBetween(this.meeting.start, this.meeting.end);
    }
  },
  methods: {
    getAttendeeName(attendee) {
      if (!isEmpty(attendee.company) && this.hasMultipleAttendees) {
        return `${attendee.company} - ${attendee.fullname}`;
      }

      return attendee.fullname;
    }
  }
};
</script>

<template>
  <div
    class="block focus:outline-none focus:bg-gray-50 border-b transition duration-150 ease-in-out"
    :class="[
      {
        'border-gray-200 bg-white hover:bg-gray-50': !isActive,
        'border-orange-300 bg-orange-100 hover:bg-orange-50': isActive
      },
      isOverClass
    ]"
  >
    <div class="px-4 py-4 sm:px-6 mb-0 sm:mb-2">
      <div class="flex flex-col sm:flex-row justify-between">
        <div class="text-sm leading-5 font-medium text-indigo-600">
          <span
            v-if="isActive"
            class="w-auto inline-block mr-1 px-1 py-1 rounded-md text-sm font-medium leading-5 bg-red-200 text-red-800"
          >
            {{ isInProgress ? 'In Progress' : 'Next Meeting' }}
          </span>
          {{ title }}
          <div class="my-1">
            <span
              class="text-gray-900"
              :class="[isOverClass]"
            >Hosted by {{ hostName }}</span>
          </div>
        </div>
        <div class="my-2 sm:my-0">
          <span
            class="text-sm leading-5 text-gray-700"
            :class="[isOverClass]"
          >
            <a-icon
              name="clock"
              size="sm"
              class="mr-1"
            />
            <span>
              <meeting-date :date="start" />
              <span class="mx-1">-</span>
              <meeting-date :date="end" />
            </span>
          </span>
        </div>
      </div>
      <div class="mt-2 sm:flex sm:justify-between">
        <div class="sm:flex">
          <div
            v-if="shouldShowNames"
            class="mr-6 flex items-center text-sm leading-5 text-gray-500"
            :class="[isOverClass]"
          >
            <div
              class="text-left"
              v-html="names"
            />
          </div>
        </div>
        <div class="mt-4 sm:mt-0 flex items-center">
          <meeting-button
            :row="meeting"
            :active="isActive"
            :is-over="isOver"
          />
        </div>
      </div>
    </div>
  </div>
</template>