<template>
  <div v-if="scheduleLoaded">
    <a-card :title="title">
      <template #description>
        Coming up in the next hour
      </template>
      <div class="bg-white overflow-hidden">
        <div
          class="block focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
        >
          <div>
            <div v-if="hasMeetingsToday">
              <meeting
                v-for="meeting in schedule"
                :key="meeting.id"
                :meeting="meeting"
              />
            </div>
            <p
              v-else
              class="text-center text-gray-400"
            >
              No Scheduled Meetings.
            </p>
          </div>
        </div>
      </div>
    </a-card>
  </div>
  <div v-else>
    <loading-cards />
  </div>
</template>

<script>
import { isEmpty, size } from '@/utils/lodash';
import { mapActions } from 'vuex';
import Meeting from '@/components/schedules/schedule/meeting.vue';
import LoadingCards from '@/components/loaders/schedule-card.vue';
export default {
  components: {
    Meeting,
    LoadingCards
  },
  data: () => ({
    scheduleInterval: null,
    scheduleLoaded: false,
    schedule: []
  }),
  computed: {
    hasMeetingsToday() {
      return !isEmpty(this.schedule);
    },
    total() {
      return size(this.schedule);
    },
    title() {
      return `${this.total} Meetings Scheduled`;
    }
  },
  created() {
    this.fetchMeetings();

    this.scheduleInterval = setInterval(() => this.fetchMeetings(), 10000);
  },
  beforeUnmount() {
    clearInterval(this.scheduleInterval);
  },
  methods: {
    ...mapActions('management', ['upcomingSchedule']),
    async fetchMeetings() {
      try {
        this.schedule = await this.upcomingSchedule();
      } catch (e) {
        console.error(e);
      } finally {
        this.scheduleLoaded = true;
      }
    }
  }
};
</script>